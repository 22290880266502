import React, { useRef } from 'react'
import './index.scss'
import { ProjectType } from '@utils/types'
import Carousel from 'react-elastic-carousel'
import { GatsbyImage } from 'gatsby-plugin-image'
import ButtonComponent from '@components/buttonComponent'
import { FadeInSection } from '@components/Animations'

type Props = {
  projects: Array<ProjectType>
}

const ProjectFooter = ({ projects }: Props) => {
  const carousel = useRef(null)
  const breakpoints = [
    {
      width: 1,
      itemsToShow: 1,
      itemPadding: [0, 0],
    },
    {
      width: 500,
      itemsToShow: 2,
      itemPadding: [0, 10],
    },
    {
      width: 767,
      itemsToShow: 3,
      itemPadding: [0, 20],
    },
  ]

  return (
    <section className="project-footer-container">
      <FadeInSection timeout={250}>
        <p className="pfooter-title">More Projects</p>
        <div>
          <Carousel
            ref={carousel}
            className="project-footer-carousel container"
            itemsToScroll={1}
            breakPoints={breakpoints}
            showArrows={false}
            pagination={false}
            enableSwipe={true}
            isRTL={false}
          >
            {projects.map((node, index) => {
              return (
                <div key={index} className="carousel-footer-item">
                  <div className="image-wrapper">
                    <div className="slide-div" />
                    <GatsbyImage
                      className="footer-image"
                      image={node?.mainImage?.asset?.gatsbyImageData}
                      alt={node?.mainImage?.alt}
                      objectFit="cover"
                    />
                  </div>
                  <div className="footer-text">
                    <p>{node?.title}</p>
                    <ButtonComponent
                      button={{
                        title: 'VIEW THIS PROJECT',
                        slug: { current: `/projects${node?.slug?.current}` },
                        type: 'primary',
                      }}
                    />
                  </div>
                </div>
              )
            })}
          </Carousel>
        </div>
      </FadeInSection>
    </section>
  )
}

export default ProjectFooter
