import React from 'react'
import { graphql } from 'gatsby'
import { ProjectType } from '@utils/types'
import SEO from '@components/seo'
import loadable from '@loadable/component'
import ProjectFooter from '@components/projectFooter'
const ProjectContent = loadable(() => import('@components/projectContent'))
const HeroTitle = loadable(() => import('@components/heroTitle'))
const ProjectInfo = loadable(() => import('@components/projectInfo'))

type Props = {
  data: {
    sanityProject: ProjectType
    sanityProjects: {
      projects: Array<ProjectType>
    }
  }
  location: Location
}

const ProjectPage = ({
  data: {
    sanityProject,
    sanityProjects: { projects },
  },
  location,
}: Props) => {
  const metadata = {
    location: location.href,
    ...sanityProject?.seo,
  }

  const getNextProject = () => {
    const index = projects.findIndex(
      (project) => project.slug.current === sanityProject.slug.current,
    )
    const next = projects[index === projects.length - 1 ? 0 : index + 1]?.slug
    const prev = projects[index === 0 ? projects.length - 1 : index - 1]?.slug
    return { next, prev }
  }

  return (
    <>
      <SEO {...metadata} />
      <HeroTitle
        image={sanityProject?.heroImage}
        title={sanityProject?.title}
        {...getNextProject()}
      />
      <ProjectInfo
        description={sanityProject?._rawDescription}
        location={sanityProject?.location || 'N/A'}
        sector={sanityProject?.category || 'N/A'}
        featuredIn={sanityProject?.featuredIn || 'N/A'}
        photos={sanityProject?.photos || 'N/A'}
      />
      <ProjectContent content={sanityProject?.content} />
      <ProjectFooter
        projects={projects.filter(
          (project) => project?.slug.current !== sanityProject?.slug.current,
        )}
      />
    </>
  )
}

export default ProjectPage

export const pageQuery = graphql`
  query sanityProjectQuery($slug: String) {
    sanityProject(slug: { current: { eq: $slug } }) {
      title
      seo {
        description
        keywords
        image
        title
        twitterCard
        twitterCreator
        twitterSite
      }
      featuredIn
      slug {
        current
      }
      _rawDescription(resolveReferences: { maxDepth: 10 })
      heroImage {
        alt
        asset {
          gatsbyImageData(
            placeholder: BLURRED
            height: 750
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      location
      photos
      category
      content {
        ... on SanityMainImage {
          _type
          alt
          asset {
            gatsbyImageData(
              placeholder: BLURRED
              height: 750
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        ... on SanitySquaredSection {
          _type
          _rawText(resolveReferences: { maxDepth: 10 })
          flip
          image {
            alt
            asset {
              gatsbyImageData(
                placeholder: BLURRED
                height: 750
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
    sanityProjects(_id: { in: ["drafts.projectsPage", "projectsPage"] }) {
      projects {
        slug {
          current
        }
        title
        mainImage {
          alt
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        hoverImage {
          alt
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
  }
`
